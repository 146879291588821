import React from "react";

import Layout from "../components/layout";
import Ausfluege from "../components/ausfluege/bamberg";
import Memmelsdorf from "../components/ausfluege/memmelsdorf";
import Back from "../components/backbutton";

function AusfluegePage() {
  return (
    <Layout>
     
     <Back></Back> 
     
     <section className="py-2">
     <Ausfluege> </Ausfluege>
       </section> 
       <section className="py-2">
       <Memmelsdorf></Memmelsdorf>   
         </section>  
     
      
    </Layout>
  );
}

export default AusfluegePage;
