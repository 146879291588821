import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import bamberg from "../../images/BambergSkyline.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1345,

  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function Ausfluege() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader           
        title="Bamberg"        
      />
      <CardMedia
        className={classes.media}
        image={bamberg}
        title="Paella dish"
        onClick={handleExpandClick}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
         Bamberg Altstadt
        </Typography>
      </CardContent>
      <CardActions disableSpacing>              
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Altstadt Bamberg:</Typography>
          <Typography paragraph>
        

Neue Residenz mit Rosengarten (Ausblick!)
Michaelskloster und Weingärten
Altes Rathaus
Gärtnerstadt (Achtung, nicht Gartenstadt!) (Gärtner- und Häckermuseum)
Stadtpark Hain mit Botanischem Garten
Villa Concordia
Altenburg
          </Typography>  Dom mit Bamberger Reiter (Dommuseum)
          <Typography paragraph>
            
          </Typography>Alte Hofhaltung
          
          <Typography paragraph>
           
          </Typography>
          <Typography>
        
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
